@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #0a192f;
  --secondary-color: #172a45;
  --accent-color: #64ffda;
  --text-color: #8892b0;
  --background-color: #0a192f;
  --white: #e6f1ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 25, 47, 0.85);
  padding: 20px 0;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--accent-color);
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 30px;
}

nav ul li a {
  color: var(--white);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--accent-color);
}

/* Section styles */
.section {
  position: relative;
  padding: 100px 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* Hero styles */
.hero {
  background-color: var(--primary-color);
  color: var(--white);
  height: 100vh;
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
}

.hero h2 {
  font-size: 4rem;
  margin-bottom: 20px;
  color: var(--white);
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  background-color: transparent;
  color: var(--accent-color);
  padding: 12px 24px;
  text-decoration: none;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: rgba(100, 255, 218, 0.1);
}

/* Services styles */
.services {
  background-color: var(--secondary-color);
  color: var(--white);
  clip-path: polygon(0 5%, 100% 0, 100% 97%, 0 100%);
  margin-top: -5vw;
}

.services h2, .about h2, .portfolio h2, .contact h2 {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.5rem;
  color: var(--white);
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.service-card {
  background-color: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  margin-bottom: 15px;
  color: var(--accent-color);
}

/* About styles */
.about {
  background-color: var(--primary-color);
  color: var(--white);
  clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 98%);
  margin-top: -3vw;
}

.about p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.about-card {
  background-color: var(--secondary-color);
  padding: 30px;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center; /* Center all text content */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%; /* Ensure all cards have the same height */
}

.about-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about-card h3 {
  color: var(--accent-color);
  margin-bottom: 15px;
  font-size: 1.5rem; /* Adjust font size as needed */
}

.about-card p {
  font-size: 1rem; /* Adjust font size as needed */
  line-height: 1.6;
}

/* Portfolio styles */
.portfolio {
  background-color: var(--secondary-color);
  color: var(--white);
  clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 96%);
  margin-top: -2vw;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

.portfolio-item {
  background-color: var(--primary-color);
  padding: 30px;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.portfolio-item h3 {
  margin-bottom: 15px;
  color: var(--accent-color);
}

/* Contact styles */
.contact {
  background-color: var(--primary-color);
  color: var(--white);
  clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 100%);
  margin-top: -4vw;
}

.contact form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact input,
.contact textarea {
  margin-bottom: 20px;
  padding: 10px;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  color: var(--white);
}

.contact textarea {
  height: 150px;
}

.contact button {
  background-color: transparent;
  color: var(--accent-color);
  padding: 12px 24px;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.contact button:hover {
  background-color: rgba(100, 255, 218, 0.1);
}

/* Footer styles */
.footer {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 40px 0;
  text-align: center;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links a {
  color: var(--white);
  margin-left: 20px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--accent-color);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.6s ease-out;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header .container {
    flex-direction: column;
  }

  nav ul {
    margin-top: 20px;
  }

  nav ul li {
    margin: 0 10px;
  }

  .hero h2 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .footer .container {
    flex-direction: column;
  }

  .social-links {
    margin-top: 20px;
  }

  .section {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    margin-top: 0;
  }

  .hero {
    clip-path: polygon(0 0, 100% 0, 100% 97%, 0 100%);
  }

  .services {
    clip-path: polygon(0 3%, 100% 0, 100% 98%, 0 100%);
    margin-top: -3vw;
  }

  .about {
    clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 99%);
    margin-top: -2vw;
  }

  .portfolio {
    clip-path: polygon(0 1%, 100% 0, 100% 100%, 0 98%);
    margin-top: -1vw;
  }

  .contact {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 100%);
    margin-top: -2vw;
  }
}